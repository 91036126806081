.sortableList > ul {
  border: 1px solid #dddedf;
  width: 260px;
  height: 400px;
  display: inline-block;
  vertical-align: top;
  overflow: scroll;
  overflow-x: hidden;
}

.sortableList > ul > li {
  background-color: #f5f5f5 !important;
  border-bottom: 1px dotted Gray;
  cursor: pointer;
  margin-bottom: 3px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.sortableList > ul,
li {
  margin: 3px;
  padding: 0;
}

.sortableList > ul {
  list-style: none;
}

.sortableList > .input-group {
  width: 260px !important;
}

.sortableList > .list-group-item {
  padding: 1px !important;
}
