@import "~react-toastify/scss/main";
@import "layout";
@import "dropdown";

select {
  -webkit-appearance: none;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  border-radius: 2px;
  margin-top: 5px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 14px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 14px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 2px;
}
div.ReactTags__selected button.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
  border: none;
}
