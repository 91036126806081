body {
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

header,
footer {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
  padding-top: 15px;
}

div[role="main"] {
  margin-top: 73px;
}

.required .text-secondary:after {
  content: " *";
  color: red;
}

sgwt-mini-footer {
  position: initial !important; // fix  style

  footer.border {
    border-left: none !important; // fix sgwt-mini-footer borders
    border-right: none !important;
  }
}
